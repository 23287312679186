import React from 'react';
import { graphql, Link } from 'gatsby';
import * as b from "../components/bootstrap.module.css"
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import Thumbnail from '../components/thumbnail';
import Breadcrumbs from "../components/breadcrumbs"
import Icon from "../components/icon"
import resolveUrl from '../utils/urls/resolve-url';
import Posts from "../components/posts";
import Layout from '../components/layout';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Intermediaries = ({
  data: { allMysqlLocalfirms, mysqlTerms, allMysqlBlog }, 
  pageContext: { breadcrumbs },
  location: { pathname }
}) => {
  return (
    <Layout pathname={pathname}>
      <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(' ')}>
        <Breadcrumbs theme='white' items={breadcrumbs}/>
      </div>
      <article className={["firm-page",b.py4].join(' ')}>
        <div className={[b.container, "blog-content", "post-content"].join(" ")}>
          <h1 className={['blackToWhite','big-title'].join(' ')}>{mysqlTerms.title}</h1>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={['blackToWhite',b.fontWeightNormal,b.mb3].join(" ")}/>
          )}
          <h2 className={['blackToWhite','title'].join(' ')}>Oferta pośredników w Polsce</h2>
          <div className={b.row}>
            {allMysqlLocalfirms.nodes.map(({ userrating,name,number_of_agents,number_of_establishments,mysqlImage,url, prefix_url, intro }, i)=>(
              <div key={i} className={[b.colLg4,b.colMd6,b.my3].join(' ')}>
                <div className={[b.p3,'home-page-white-box'].join(' ')}>
                  <div>
                    <div className={[b.dFlex,b.justifyContentBetween,b.my3].join(' ')}>
                      <div className={['font-size-24', 'tp-name', 'blackToWhite'].join(' ')}>{name}</div>
                      <div className="dark-mode-image"><Thumbnail image={mysqlImage} alt={name + ' | akredo.pl'}/></div>
                    </div>
                    <div className="firm-row-likes-wrapper">
                      <div className={["firm-row-likes",b.dFlex,b.px2,b.justifyContentCenter,].join(" ")}>
                        <div className={["stars-container", b.mr2,'bank-list-stars'].join(" ")}>
                          <div className="star-box" style={{ width: ` ${userrating}% ` }}>
                            <Icon size={16} icon="Star" className={b.mr1} />
                            <Icon size={16} icon="Star" className={b.mr1} />
                            <Icon size={16} icon="Star" className={b.mr1} />
                            <Icon size={16} icon="Star" className={b.mr1} />
                            <Icon size={16} icon="Star" />
                          </div>
                          <div className="empty-star-box">
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" />
                          </div>
                        </div>
                        <p className={[b.my0, "blackToWhite"].join(" ")}>
                          {(userrating / 20).toFixed(1)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {intro&&(
                      <div className={['font-size-13','blackToWhite'].join(' ')} dangerouslySetInnerHTML={{ __html: intro }} />
                    )}
                  </div>
                  <div>
                    <div className={[b.dFlex,b.alignItemsCenter,b.mt2].join(' ')}>
                      <div><Icon size={40} icon="Branches" className={b.mr2} /></div>
                      <div>
                        <small className='blueToWhite'>Ilość placówek</small>
                        <p className={['font-size-18',b.my0].join(' ')}>{number_of_establishments}</p>
                      </div>
                    </div>
                    <div className={[b.dFlex,b.alignItemsCenter,b.my2].join(' ')}>
                      <div><Icon size={40} icon="Co-workers" className={b.mr2} /></div>
                      <div>
                        <small className='blueToWhite'>Ilość agentów</small>
                        <p className={['font-size-18',b.my0].join(' ')}>{number_of_agents}</p>
                      </div>
                    </div>
                    <Link to={resolveUrl(prefix_url, url)}>
                      <span className={[b.btn,"btn-info",b.mb0,"contact-btn",b.mt3].join(' ')}>
                        <Icon size={20} icon="Info" color="#FFFFFF"className={b.mr1}/>
                        Więcej o {name}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")}>
            <div className={[b.colXl4, b.mb3, b.orderXlLast].join(' ')}>
              <TableOfContents selector=".post-content h2[id]" />
            </div>

            <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
            {mysqlTerms.description_alt && (
              <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
            )}
            {mysqlTerms.description_additional && (
              <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
            )}
            </div>
          </div>
        </div>

        {allMysqlBlog?.nodes?.length > 0 && (
          <aside className={[b.container, b.my5, b.ptLg5].join(" ")}>
            <h2 className={["title", b.mb3].join(' ')}>Treści powiązane z {mysqlTerms.term}</h2>
            <Posts items={allMysqlBlog?.nodes} />
          </aside>
        )}
      </article>
    </Layout>
  )
}

export default Intermediaries

export const query = graphql `
query Intermediaries($mysqlId: Int!, $withoutUrls: [String]) {
  allMysqlLocalfirms(filter: {type: {eq: 5}, isHeadquarters: { eq: 1 }}) {
    nodes {
      description
      userrating
      name
      number_of_agents
      number_of_establishments
      intro
      url
      prefix_url
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, term_id: {eq: $mysqlId}, is_category: {eq: null}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description_additional
    description_alt
    description
    title
    term
  }
}

`




